/* styles.css or your existing CSS file */
.single-portfolio-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.single-portfolio-item img {
  object-fit: cover;
  object-position: top;
}


.portfolio-videos video {
  max-height: 400px;
}